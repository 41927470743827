import * as ReactDOM from "react-dom";
import toast, { Toaster } from 'react-hot-toast';

(window as any).lbr = {
    toast
};

const root = document.getElementById("react-notification-area");

try {
    ReactDOM.render(
        <>
            <Toaster position='top-right' toastOptions={{
                duration: 2000,
                success: {
                    style: {
                    background: '#d4edda',
                    color: '#155724'
                    },
                },
                error: {
                    style: {
                      background: '#f8d7da',
                        color: '#721c24'
                    },
                  },
            }} />
        </>,
    root);
} catch (e) {
    console.error(e);
}
